import { mande } from 'mande';
const apipath = process.env.NODE_ENV == 'development' ? 'http://localhost:8899/api/admin' : '/api/admin'
const request = mande(process.env.apihost || apipath)


// send
const send = async function(name, req){
  req = req || {}
  console.log('req', {name,  req})
  try {    
    let resp = await request.post({name, req})    
    console.log('resp', {name, resp})
    if (!resp) resp = {}
    return resp

  } catch (error) {
    console.error(error.body)
    return {error:{id:'connection', code: 500}}    
  }
};


// api
export default {
  
  async send(name, req) {return await send(name,req)},

  // login
  async login(pass) {return await send('login',{pass})},
  async logout() {return await send('logout')},
  
  // data
  async list(limit, offset, q) {return await send('admin.users',{limit,offset,q})},
  async search(q) {return await send('search',q)},
  
  //download copy file
  async download() {
      console.log("download")
      let name = 'download'
      
      const res = await fetch(apipath, {method: 'POST', body: JSON.stringify({name, req:{}})});
      console.log(res)      
      let data = await res.blob()
      // console.log("data", data)

      // error
      if (data.type == 'text/plain'){        
        let text = await data.text()
        // console.log("data text",text)
        let json = JSON.parse(text)
        // console.log("json",json)
        return json
      }
      
      // ok
      await this.downloadBlob(data, 'changes.json')
      return {resp:{}}
  },


    async downloadBlob(blob, name) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) return window.navigator.msSaveOrOpenBlob(blob);

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      link.download = name;

      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
          new MouseEvent('click', { 
              bubbles: true, 
              cancelable: true, 
              view: window 
          })
      );

      setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
      }, 100);
    },


    // copy id
async uploadFile(body){
  return new Promise(function (resolve, reject) {
    const rs = new XMLHttpRequest();
    let host = `${apipath}/restore`;
    rs.open('post', host, true);
    rs.onload = function () {
      if (rs.status != 200)
        reject({ error: { id: 'try' } });
      if (!rs.response) resolve({});
      const resp = JSON.parse(rs.response);
      resolve(resp);
    };
    const uint8 = new Uint8Array(body);
    rs.send(uint8);
  });
},



}





