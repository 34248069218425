// tools function
import { humanize } from 'humanize';
export default {

  size(bytes){
    return humanize.filesize(bytes)
  },

  async readFileAsBase64(file) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = e => {resolve(e.target.result)};
        reader.readAsDataURL(file);
    });
  },

  async readFileAsText(file) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = e => {resolve(e.target.result)};
        reader.readAsText(file);
    });
  },

  async readFileAsBytes(file) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = e => {resolve(e.target.result)};
        reader.readAsArrayBuffer(file);
    });
  },


  // await imageSize(file)
  imageSize(file) {
    return new Promise (function (resolved, rejected) {
      var i = new Image()
      i.onload = function(){
        resolved({width: i.width, height: i.height, box: i.width == i.height, vertical: i.width < i.height})
      };
      i.src = file
    })
  },

  copylink(id, title, type){
    
    title = this.normalize(title)
    title = this.cleanLinkText(title)
    title = title.replace(/ /g,'-')    
    // console.log('copylink title', title)

    type = this.normalize(type)
    type = this.cleanLinkText(type)
    type = type.replace(/ /g,'-')
    
    let link;
    if (!title) {
      link = `/${type}-${id}`
    } else{
      link = `/${type}-${title}-${id}`
    }
    link = link.replaceAll('--','-')
    return link
  },

  // generate zip file name download
  copyname(title, type){
    
    title = this.normalize(title)
    title = this.cleanLinkText(title)
    title = title.replace(/ /g,'-')
    
    type = this.normalize(type)
    type = this.cleanLinkText(type)
    type = type.replace(/ /g,'-')    
    return `${title}-${type}-meetcopy.zip`
  },

  // generate zip file name download
  copyLeadsName(title, format){
    
    title = this.normalize(title)
    title = this.cleanLinkText(title)
    title = title.replace(/ /g,'-')
    
    if (!format) format = 'csv'
    return `leads-${title}.${format}.zip`
  },

  cleanLinkText(text){
    if (!text) return
    text = text.replace(/[^0-9a-zа-яёА-ЯЁ]/gi, ' ')
    text = text.replace(/ +(?= )/g,'')
    return text
  },

  numlist(from, to, step, fixed){
      if (!from) from = 0
      if (!to) to = 100
      if (!step) step = 1
      
      let list = [];
      for ( var i=from, l=to; i<=l; i+=step ){
        fixed ? list.push(i.toFixed(fixed)) : list.push(i)
      }       
      return list || []
  },
  
  title(t) {
    return t.charAt(0).toUpperCase() + t.slice(1);
  },

  textlimit(line, max) {
    return line.substring(0, max) + '...'
  },

  push(list, item) {
    if (!list) return
    let id = list.indexOf(item)
    id > -1 ? list.splice(id, 1) : list.push(item)
  },

  has(list, item) {
    if (!list) return
    return list.indexOf(item) > -1
  },

  len(list) {
    if (!list) return
    return list.length
  },

  delete(list, id) {
    if (!list) return
    let pos = list.indexOf(id)
    if (pos > -1) {
      list.splice(pos, 1);
    }
  },

  move(from, to, id) {
    for (var i = 0; i < from.length; i++) {
      if (from[i].id == id) {
        to.unshift(from[i]);
        from.splice(i, 1);
        i--;
        return
      }
    }
  },

  // format numbers
  fm(num, delim, k) {
    if (isNaN(num)) return ''
    if (k) {
      num = Math.round(num / 1000)
      return num + 'k'
    }
    if (!delim) delim = ' '
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delim)
  },

  //use:
  //many(42, 'найдена', 'найдено', 'найдены');
  many(num, one, five, many) {    
    let cases = [2, 0, 1, 1, 1, 2];
    let id = (num % 100 > 4 && num % 100 < 20) ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]
    switch (id) {
      case 0: return one
      case 1: return five
      case 2: return many
    }
  },

  // now date
  now(){
    let d = new Date()
    return d
  },

  

  ago(unix, lang, customtraslate) {
    let langs = {
        en:{now:'now',ago:'ago',next:'in',sec:'seconds',min:'minute',hours:'hours',days:'days',month:'months', years:'years'},
        ru:{now:'now',ago:'ago',next:'in',sec:'seconds',min:'minute',hours:'hours',days:'days',month:'months', years:'years'},
    }
    if (!langs[lang]) lang = 'en'
    let translate = langs[lang]
    if (customtraslate) translate = customtraslate
    
    let tt = new Date()
    var sec = Math.round((tt.getTime() / 1000) - unix)
    let back = translate.ago
    let front = translate.next;
    if (sec < 0) {
      sec = sec * -1
      back = ''
    } else {
      front = ''
    }

    if (sec < 0) return translate.now
    if (sec > 0 && sec < 60) return `${front} ${sec} ${translate.sec} ${back}`
    if (sec > 60 && sec < 60 * 60) {
      let m = Math.round(sec / 60)
      return `${front} ${m} ${translate.min} ${back}` 
    }
    if (sec >= 60 * 60 && sec < 60 * 60 * 24) {
      let m = Math.round(sec / (60 * 60))
      return `${front} ${m} ${translate.hours} ${back}`
    }
    if (sec >= 60 * 60 * 24 && sec < 60 * 60 * 24 * 30) {
      let m = Math.round(sec / (60 * 60 * 24))
      return `${front} ${m} ${translate.days} ${back}`
    }
    if (sec >= 60 * 60 * 24 * 30 && sec < 60 * 60 * 24 * 365) {
      let m = Math.round(sec / (60 * 60 * 24 * 30))
      return `${front} ${m} ${translate.month} ${back}`
    }
    if (sec >= 60 * 60 * 24 * 365) {
      let m = Math.round(sec / (60 * 60 * 24 * 365))
      return `${front} ${m} ${translate.years} ${back}`
    }
  },

  openlink(url){
    let win = window.open(url, '_blank');
    win.focus();
  },

  popuplink(link, w,h){
    if (!link) return
    if (!w) w = 800
    if (!h) h = 600
    let size = `width=${w},height=${h}`
    window.open(link, "source", size);
    window.close();
  },

  // window width
  width() {
    var myWidth = 0;
    if( typeof( window.innerWidth ) == 'number' ) {
        myWidth = window.innerWidth;
    } else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) {
        myWidth = document.documentElement.clientWidth;
    } else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) ) {
        myWidth = document.body.clientWidth;
    }
    return myWidth
  },

  // window height
  height() {
    var myHeight = 0;
    if( typeof( window.innerWidth ) == 'number' ) {
        myHeight = window.innerHeight;
    } else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) {
        myHeight = document.documentElement.clientHeight;
    } else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) ) {
        myHeight = document.body.clientHeight;
    }
    return myHeight
  },

  deleteAllCookies() {
    let cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) { let cookie = cookies[i]; let eqPos = cookie.indexOf("="); let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie; document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";}
},

cookie(name) {
  name = name + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
},

  // refresh page
  refresh() {
    window.location.reload()
  },

  translit(text){
    var answer = '';
    var converter = {
      'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
      'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
      'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
      'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
      'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
      'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
      'э': 'e',    'ю': 'yu',   'я': 'ya',
      'А': 'A',    'Б': 'B',    'В': 'V',    'Г': 'G',    'Д': 'D',
      'Е': 'E',    'Ё': 'E',    'Ж': 'Zh',   'З': 'Z',    'И': 'I',
      'Й': 'Y',    'К': 'K',    'Л': 'L',    'М': 'M',    'Н': 'N',
      'О': 'O',    'П': 'P',    'Р': 'R',    'С': 'S',    'Т': 'T',
      'У': 'U',    'Ф': 'F',    'Х': 'H',    'Ц': 'C',    'Ч': 'Ch',
      'Ш': 'Sh',   'Щ': 'Sch',  'Ь': '',     'Ы': 'Y',    'Ъ': '',
      'Э': 'E',    'Ю': 'Yu',   'Я': 'Ya'
    };
   
    for (var i = 0; i < text.length; ++i ) {
      if (converter[text[i]] == undefined){
        answer += text[i];
      } else {
        answer += converter[text[i]];
      }
    }
   
    return answer;
  },

  normalize(text){
    if (!text) return
    //https://stackoverflow.com/questions/11815883/convert-non-ascii-characters-umlauts-accents-to-their-closest-ascii-equiva
    var combining = /[\u0300-\u036F]/g; 
    return text.normalize('NFKD').replace(combining, '')
  },

  nospaces(text){
    return text.replace(/ /g,'')
  },

  //download base64 image
   download64(image, filename) {
    const downloadLink = document.createElement("a");
    downloadLink.href = image;
    downloadLink.download = filename;
    downloadLink.click();
  },

  unix(){
    return Date.now()
  },

  rand(min, max){
    if (!min) min = 0
    if (!max) max = 1000000000000
    return Math.random() * (max - min) + min;
  },

  resize(body, max){
    let r = this.imageSize(body)
    let width, height;
    
    if (r.width == r.height){width = max; height = max}
    if (r.width > r.height) {width = max; height = max/(r.width/r.height)}
    if (r.width < r.height) {height = max; width = max/(r.height/r.width)}

    const img = document.createElement('img');
    img.src = body;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = width;
    canvas.height = height;

    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL();
  },

  ext(filename){
    return filename.split('.').pop()
  },

  unixdate(v){
    var date = new Date(v * 1000);
    return this.jsDateFormat(date)
  },

  

  // YYYY-MM-DD
  jsDateFormat(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
},

langToOgMetaLocale(lang, div){
  if (!lang) lang = 'en'
  if (lang == 'en'){
    return `${lang}${div || '-'}US`
  } else{
    return `${lang}${div || '-'}${lang.toUpperCase()}`
  }
},

valid:{
  // simple validation
  email(email){
      // console.log('tools valid email',email)
      if (!email) return
      let p = email.split('@')
      if (!p) return
      if (p.length != 2) return
      if  (p[1].split('.') <2 ) return
      return true
  },

  // link
  link(string) {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }
},

time:{
  year(){
    let d = new Date()
    return d.getFullYear()
  },
},


color:{
  
  // tools.color.dark(color) => bool
  dark(color) { //is dark... bool
    let r,g,b;
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // console.log('rgb color')
      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);  
      r = color[1];
      g = color[2];
      b = color[3];
    } 
    else {  
      // console.log('hex color')
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace( color.length < 5 && /./g, '$&$&'));  
      // console.log('hex color',color)
      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }
  
    // HSP equation from http://alienryderflex.com/hsp.html
    let hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );
  
    // Using the HSP value, determine whether the color is light or dark
    // console.log('hex color hsp', hsp)
    return hsp > 127 ? false : true;
    
  },
  // bgColor = "#EE7AB7"
  // brightness = lightOrDark(bgColor);
  // console.log(brightness, hsp)
  
  
  // await tools.color.brightness(image) = int 0 - black, 255 - white
  brightness(image) {

    return new Promise (function (resolved, rejected) {
      
      var img = document.createElement("img");
      img.src = image;
      img.style.display = "none";
      document.body.appendChild(img);

      var colorSum = 0;

      img.onload = function() {
          // create canvas
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;

          var ctx = canvas.getContext("2d");
          ctx.drawImage(this,0,0);

          var imageData = ctx.getImageData(0,0,canvas.width,canvas.height);
          var data = imageData.data;
          var r,g,b,avg;

            for(var x = 0, len = data.length; x < len; x+=4) {
              r = data[x];
              g = data[x+1];
              b = data[x+2];

              avg = Math.floor((r+g+b)/3);
              colorSum += avg;
          }

          var brightness = Math.floor(colorSum / (this.width*this.height));
          resolved(brightness);
      }

    })


    
}
},
  
}