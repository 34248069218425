import api from '@/ts/api';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

export const Store = defineStore('store', {
  state: () => ({ 
      logged : useStorage('logged', false),    
      lang : useStorage('geolang', 'en'),    
      limit : useStorage('limit', 20),    
  }),
  
  actions: {

    //login
    async login(pass){
      let res = await api.login(pass)          
      if (!res) return {error:{id:'internal'}}
      if (res.error) return res.error
      this.logged = true
      return {}
    },

    // выход из текущего аккаунта и переключение на следующий
    async logout(){

      const res = await api.logout()
      if (!res){
        console.error("no api result")
       
        return
      }
      if (res.error) {
        console.error(res.error)
       
        return
      }

        // удаляем куки
        console.log("logout clear")
        this.logged = false
        
        window.location = '/'
        return
    },


  },
})

