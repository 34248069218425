export default {
ad: {name:"Andorra", "native":"Andorra", "phone": "376", "continent": "EU", "capital": "Andorra la Vella", "currency":"EUR", "languages": "ca", },
ae: {name:"UAE", "native":"دولة الإمارات العربية المتحدة", "phone": "971", "continent": "AS", "capital": "Abu Dhabi", "currency":"AED", "languages": "ar", },
af: {name:"Afghanistan", "native":"افغانستان", "phone": "93", "continent": "AS", "capital": "Kabul", "currency":"AFN", "languages": "ps,uz,tk", },
ag: {name:"Antigua and Barbuda", "native":"Antigua and Barbuda", "phone": "1268", "continent": "NA", "capital": "Saint John's", "currency":"XCD", "languages": "en", },
ai: {name:"Anguilla", "native":"Anguilla", "phone": "1264", "continent": "NA", "capital": "The Valley", "currency":"XCD", "languages": "en", },
al: {name:"Albania", "native":"Shqipëria", "phone": "355", "continent": "EU", "capital": "Tirana", "currency":"ALL", "languages": "sq", },
am: {name:"Armenia", "native":"Հայաստան", "phone": "374", "continent": "AS", "capital": "Yerevan", "currency":"AMD", "languages": "hy,ru", },
ao: {name:"Angola", "native":"Angola", "phone": "244", "continent": "AF", "capital": "Luanda", "currency":"AOA", "languages": "pt", },
aq: {name:"Antarctica", "native":"Antarctica", "phone": "0", "continent": "AN", "capital": "Center", "currency":"USD", "languages": "en", },
ar: {name:"Argentina", "native":"Argentina", "phone": "54", "continent": "SA", "capital": "Buenos Aires", "currency":"ARS", "languages": "es,gn", },
as: {name:"American Samoa", "native":"American Samoa", "phone": "1684", "continent": "OC", "capital": "Pago Pago", "currency":"USD", "languages": "en,sm", },
at: {name:"Austria", "native":"Österreich", "phone": "43", "continent": "EU", "capital": "Vienna", "currency":"EUR", "languages": "de", },
au: {name:"Australia", "native":"Australia", "phone": "61", "continent": "OC", "capital": "Canberra", "currency":"AUD", "languages": "en", "top": "aaa", },
aw: {name:"Aruba", "native":"Aruba", "phone": "297", "continent": "NA", "capital": "Oranjestad", "currency":"AWG", "languages": "nl,pa", },
ax: {name:"Åland", "native":"Åland", "phone": "358", "continent": "EU", "capital": "Mariehamn", "currency":"EUR", "languages": "sv", },
az: {name:"Azerbaijan", "native":"Azərbaycan", "phone": "994", "continent": "AS", "capital": "Baku", "currency":"AZN", "languages": "az,hy", },
ba: {name:"Bosnia and Herzegovina", "native":"Bosna i Hercegovina", "phone": "387", "continent": "EU", "capital": "Sarajevo", "currency":"BAM", "languages": "bs,hr,sr", },
bb: {name:"Barbados", "native":"Barbados", "phone": "1246", "continent": "NA", "capital": "Bridgetown", "currency":"BBD", "languages": "en", },
bd: {name:"Bangladesh", "native":"Bangladesh", "phone": "880", "continent": "AS", "capital": "Dhaka", "currency":"BDT", "languages": "bn", },
be: {name:"Belgium", "native":"België", "phone": "32", "continent": "EU", "capital": "Brussels", "currency":"EUR", "languages": "nl,fr,de", },
bf: {name:"Burkina Faso", "native":"Burkina Faso", "phone": "226", "continent": "AF", "capital": "Ouagadougou", "currency":"XOF", "languages": "fr,ff", },
bg: {name:"Bulgaria", "native":"България", "phone": "359", "continent": "EU", "capital": "Sofia", "currency":"BGN", "languages": "bg", },
bh: {name:"Bahrain", "native":"البحرين", "phone": "973", "continent": "AS", "capital": "Manama", "currency":"BHD", "languages": "ar", },
bi: {name:"Burundi", "native":"Burundi", "phone": "257", "continent": "AF", "capital": "Bujumbura", "currency":"BIF", "languages": "fr,rn", },
bj: {name:"Benin", "native":"Bénin", "phone": "229", "continent": "AF", "capital": "Porto-Novo", "currency":"XOF", "languages": "fr", },
bl: {name:"Saint Barthélemy", "native":"Saint-Barthélemy", "phone": "590", "continent": "NA", "capital": "Gustavia", "currency":"EUR", "languages": "fr", },
bm: {name:"Bermuda", "native":"Bermuda", "phone": "1441", "continent": "NA", "capital": "Hamilton", "currency":"BMD", "languages": "en", },
bn: {name:"Brunei", "native":"Negara Brunei Darussalam", "phone": "673", "continent": "AS", "capital": "Bandar Seri Begawan", "currency":"BND", "languages": "ms", },
bo: {name:"Bolivia", "native":"Bolivia", "phone": "591", "continent": "SA", "capital": "Sucre", "currency":"BOV", "languages": "es,ay,qu", },
bq: {name:"Bonaire", "native":"Bonaire", "phone": "5997", "continent": "NA", "capital": "Kralendijk", "currency":"USD", "languages": "nl", },
br: {name:"Brazil", "native":"Brasil", "phone": "55", "continent": "SA", "capital": "Brasília", "currency":"BRL", "languages": "pt", },
bs: {name:"Bahamas", "native":"Bahamas", "phone": "1242", "continent": "NA", "capital": "Nassau", "currency":"BSD", "languages": "en", },
bt: {name:"Bhutan", "native":"ʼbrug-yul", "phone": "975", "continent": "AS", "capital": "Thimphu", "currency":"BTN", "languages": "dz", },
bv: {name:"Bouvet Island", "native":"Bouvetøya", "phone": "", "continent": "AN", "capital": "", "currency":"NOK", "languages": "", },
bw: {name:"Botswana", "native":"Botswana", "phone": "267", "continent": "AF", "capital": "Gaborone", "currency":"BWP", "languages": "en,tn", },
by: {name:"Belarus", "native":"Белару́сь", "phone": "375", "continent": "EU", "capital": "Minsk", "currency":"BYR", "languages": "be,ru", },
bz: {name:"Belize", "native":"Belize", "phone": "501", "continent": "NA", "capital": "Belmopan", "currency":"BZD", "languages": "en,es", },
ca: {name:"Canada", "native":"Canada", "phone": "1", "continent": "NA", "capital": "Ottawa", "currency":"CAD", "languages": "en,fr", "top": "aaa", },
cc: {name:"Cocos [Keeling] Islands", "native":"Cocos (Keeling) Islands", "phone": "61", "continent": "AS", "capital": "West Island", "currency":"AUD", "languages": "en", },
cd: {name:"Democratic Republic of the Congo", "native":"République démocratique du Congo", "phone": "243", "continent": "AF", "capital": "Kinshasa", "currency":"CDF", "languages": "fr,ln,kg,sw,lu", },
cf: {name:"Central African Republic", "native":"Ködörösêse tî Bêafrîka", "phone": "236", "continent": "AF", "capital": "Bangui", "currency":"XAF", "languages": "fr,sg", },
cg: {name:"Republic of the Congo", "native":"République du Congo", "phone": "242", "continent": "AF", "capital": "Brazzaville", "currency":"XAF", "languages": "fr,ln", },
ch: {name:"Switzerland", "native":"Schweiz", "phone": "41", "continent": "EU", "capital": "Bern", "currency":"CHF", "languages": "de,fr,it", },
ci: {name:"Ivory Coast", "native":"Côte d'Ivoire", "phone": "225", "continent": "AF", "capital": "Yamoussoukro", "currency":"XOF", "languages": "fr", },
ck: {name:"Cook Islands", "native":"Cook Islands", "phone": "682", "continent": "OC", "capital": "Avarua", "currency":"NZD", "languages": "en", },
cl: {name:"Chile", "native":"Chile", "phone": "56", "continent": "SA", "capital": "Santiago", "currency":"CLF", "languages": "es", },
cm: {name:"Cameroon", "native":"Cameroon", "phone": "237", "continent": "AF", "capital": "Yaoundé", "currency":"XAF", "languages": "en,fr", },
cn: {name:"China", "native":"中国", "phone": "86", "continent": "AS", "capital": "Beijing", "currency":"CNY", "languages": "zh", },
co: {name:"Colombia", "native":"Colombia", "phone": "57", "continent": "SA", "capital": "Bogotá", "currency":"COP", "languages": "es", },
cr: {name:"Costa Rica", "native":"Costa Rica", "phone": "506", "continent": "NA", "capital": "San José", "currency":"CRC", "languages": "es", },
cu: {name:"Cuba", "native":"Cuba", "phone": "53", "continent": "NA", "capital": "Havana", "currency":"CUP", "languages": "es", },
cv: {name:"Cape Verde", "native":"Cabo Verde", "phone": "238", "continent": "AF", "capital": "Praia", "currency":"CVE", "languages": "pt", },
cw: {name:"Curacao", "native":"Curaçao", "phone": "5999", "continent": "NA", "capital": "Willemstad", "currency":"ANG", "languages": "nl,pa,en", },
cx: {name:"Christmas Island", "native":"Christmas Island", "phone": "61", "continent": "AS", "capital": "Flying Fish Cove", "currency":"AUD", "languages": "en", },
cy: {name:"Cyprus", "native":"Κύπρος", "phone": "357", "continent": "EU", "capital": "Nicosia", "currency":"EUR", "languages": "el,tr,hy", },
cz: {name:"Czech Republic", "native":"Česká republika", "phone": "420", "continent": "EU", "capital": "Prague", "currency":"CZK", "languages": "cs,sk", },
de: {name:"Germany", "native":"Deutschland", "phone": "49", "continent": "EU", "capital": "Berlin", "currency":"EUR", "languages": "de", },
dj: {name:"Djibouti", "native":"Djibouti", "phone": "253", "continent": "AF", "capital": "Djibouti", "currency":"DJF", "languages": "fr,ar", },
dk: {name:"Denmark", "native":"Danmark", "phone": "45", "continent": "EU", "capital": "Copenhagen", "currency":"DKK", "languages": "da", },
dm: {name:"Dominica", "native":"Dominica", "phone": "1767", "continent": "NA", "capital": "Roseau", "currency":"XCD", "languages": "en", },
do: {name:"Dominican Republic", "native":"República Dominicana", "phone": "1809,1829,1849", "continent": "NA", "capital": "Santo Domingo", "currency":"DOP", "languages": "es", },
dz: {name:"Algeria", "native":"الجزائر", "phone": "213", "continent": "AF", "capital": "Algiers", "currency":"DZD", "languages": "ar", },
ec: {name:"Ecuador", "native":"Ecuador", "phone": "593", "continent": "SA", "capital": "Quito", "currency":"USD", "languages": "es", },
ee: {name:"Estonia", "native":"Eesti", "phone": "372", "continent": "EU", "capital": "Tallinn", "currency":"EUR", "languages": "et", },
eg: {name:"Egypt", "native":"مصر", "phone": "20", "continent": "AF", "capital": "Cairo", "currency":"EGP", "languages": "ar", },
eh: {name:"Western Sahara", "native":"الصحراء الغربية", "phone": "212", "continent": "AF", "capital": "El Aaiún", "currency":"MAD", "languages": "es", },
er: {name:"Eritrea", "native":"ኤርትራ", "phone": "291", "continent": "AF", "capital": "Asmara", "currency":"ERN", "languages": "ti,ar,en", },
es: {name:"Spain", "native":"España", "phone": "34", "continent": "EU", "capital": "Madrid", "currency":"EUR", "languages": "es,eu,ca,gl,oc", },
et: {name:"Ethiopia", "native":"ኢትዮጵያ", "phone": "251", "continent": "AF", "capital": "Addis Ababa", "currency":"ETB", "languages": "am", },
fi: {name:"Finland", "native":"Suomi", "phone": "358", "continent": "EU", "capital": "Helsinki", "currency":"EUR", "languages": "fi,sv", },
fj: {name:"Fiji", "native":"Fiji", "phone": "679", "continent": "OC", "capital": "Suva", "currency":"FJD", "languages": "en,fj,hi,ur", },
fk: {name:"Falkland Islands", "native":"Falkland Islands", "phone": "500", "continent": "SA", "capital": "Stanley", "currency":"FKP", "languages": "en", },
fm: {name:"Micronesia", "native":"Micronesia", "phone": "691", "continent": "OC", "capital": "Palikir", "currency":"USD", "languages": "en", },
fo: {name:"Faroe Islands", "native":"Føroyar", "phone": "298", "continent": "EU", "capital": "Tórshavn", "currency":"DKK", "languages": "fo", },
fr: {name:"France", "native":"France", "phone": "33", "continent": "EU", "capital": "Paris", "currency":"EUR", "languages": "fr", },
ga: {name:"Gabon", "native":"Gabon", "phone": "241", "continent": "AF", "capital": "Libreville", "currency":"XAF", "languages": "fr", },
gb: {name:"UK", "native":"United Kingdom", "phone": "44", "continent": "EU", "capital": "London", "currency":"GBP", "languages": "en", "top": "aaa", },
uk: {name:"UK", "native":"United Kingdom", "phone": "44", "continent": "EU", "capital": "London", "currency":"GBP", "languages": "en", "top": "aaa", },
gd: {name:"Grenada", "native":"Grenada", "phone": "1473", "continent": "NA", "capital": "St. George's", "currency":"XCD", "languages": "en", },
ge: {name:"Georgia", "native":"საქართველო", "phone": "995", "continent": "AS", "capital": "Tbilisi", "currency":"GEL", "languages": "ka", },
gf: {name:"French Guiana", "native":"Guyane française", "phone": "594", "continent": "SA", "capital": "Cayenne", "currency":"EUR", "languages": "fr", },
gg: {name:"Guernsey", "native":"Guernsey", "phone": "44", "continent": "EU", "capital": "St. Peter Port", "currency":"GBP", "languages": "en,fr", },
gh: {name:"Ghana", "native":"Ghana", "phone": "233", "continent": "AF", "capital": "Accra", "currency":"GHS", "languages": "en", },
gi: {name:"Gibraltar", "native":"Gibraltar", "phone": "350", "continent": "EU", "capital": "Gibraltar", "currency":"GIP", "languages": "en", },
gl: {name:"Greenland", "native":"Kalaallit Nunaat", "phone": "299", "continent": "NA", "capital": "Nuuk", "currency":"DKK", "languages": "kl", },
gm: {name:"Gambia", "native":"Gambia", "phone": "220", "continent": "AF", "capital": "Banjul", "currency":"GMD", "languages": "en", },
gn: {name:"Guinea", "native":"Guinée", "phone": "224", "continent": "AF", "capital": "Conakry", "currency":"GNF", "languages": "fr,ff", },
gp: {name:"Guadeloupe", "native":"Guadeloupe", "phone": "590", "continent": "NA", "capital": "Basse-Terre", "currency":"EUR", "languages": "fr", },
gq: {name:"Equatorial Guinea", "native":"Guinea Ecuatorial", "phone": "240", "continent": "AF", "capital": "Malabo", "currency":"XAF", "languages": "es,fr", },
gr: {name:"Greece", "native":"Ελλάδα", "phone": "30", "continent": "EU", "capital": "Athens", "currency":"EUR", "languages": "el", },
gs: {name:"South Georgia and the South Sandwich Islands", "native":"South Georgia", "phone": "500", "continent": "AN", "capital": "King Edward Point", "currency":"GBP", "languages": "en", },
gt: {name:"Guatemala", "native":"Guatemala", "phone": "502", "continent": "NA", "capital": "Guatemala City", "currency":"GTQ", "languages": "es", },
gu: {name:"Guam", "native":"Guam", "phone": "1671", "continent": "OC", "capital": "Hagåtña", "currency":"USD", "languages": "en,ch,es", },
gw: {name:"Guinea-Bissau", "native":"Guiné-Bissau", "phone": "245", "continent": "AF", "capital": "Bissau", "currency":"XOF", "languages": "pt", },
gy: {name:"Guyana", "native":"Guyana", "phone": "592", "continent": "SA", "capital": "Georgetown", "currency":"GYD", "languages": "en", },
hk: {name:"Hong Kong", "native":"香港", "phone": "852", "continent": "AS", "capital": "City of Victoria", "currency":"HKD", "languages": "zh,en", },
hm: {name:"Heard Island and McDonald Islands", "native":"Heard Island and McDonald Islands", "phone": "", "continent": "AN", "capital": "", "currency":"AUD", "languages": "en", },
hn: {name:"Honduras", "native":"Honduras", "phone": "504", "continent": "NA", "capital": "Tegucigalpa", "currency":"HNL", "languages": "es", },
hr: {name:"Croatia", "native":"Hrvatska", "phone": "385", "continent": "EU", "capital": "Zagreb", "currency":"HRK", "languages": "hr", },
ht: {name:"Haiti", "native":"Haïti", "phone": "509", "continent": "NA", "capital": "Port-au-Prince", "currency":"USD", "languages": "fr,ht", },
hu: {name:"Hungary", "native":"Magyarország", "phone": "36", "continent": "EU", "capital": "Budapest", "currency":"HUF", "languages": "hu", },
id: {name:"Indonesia", "native":"Indonesia", "phone": "62", "continent": "AS", "capital": "Jakarta", "currency":"IDR", "languages": "id", },
ie: {name:"Ireland", "native":"Éire", "phone": "353", "continent": "EU", "capital": "Dublin", "currency":"EUR", "languages": "ga,en", },
il: {name:"Israel", "native":"יִשְׂרָאֵל", "phone": "972", "continent": "AS", "capital": "Jerusalem", "currency":"ILS", "languages": "he,ar", },
im: {name:"Isle of Man", "native":"Isle of Man", "phone": "44", "continent": "EU", "capital": "Douglas", "currency":"GBP", "languages": "en,gv", },
in: {name:"India", "native":"भारत", "phone": "91", "continent": "AS", "capital": "New Delhi", "currency":"INR", "languages": "hi,en", },
io: {name:"British Indian Ocean Territory", "native":"British Indian Ocean Territory", "phone": "246", "continent": "AS", "capital": "Diego Garcia", "currency":"USD", "languages": "en", },
iq: {name:"Iraq", "native":"العراق", "phone": "964", "continent": "AS", "capital": "Baghdad", "currency":"IQD", "languages": "ar,ku", },
ir: {name:"Iran", "native":"ایران", "phone": "98", "continent": "AS", "capital": "Tehran", "currency":"IRR", "languages": "fa", },
is: {name:"Iceland", "native":"Ísland", "phone": "354", "continent": "EU", "capital": "Reykjavik", "currency":"ISK", "languages": "is", },
it: {name:"Italy", "native":"Italia", "phone": "39", "continent": "EU", "capital": "Rome", "currency":"EUR", "languages": "it", },
je: {name:"Jersey", "native":"Jersey", "phone": "44", "continent": "EU", "capital": "Saint Helier", "currency":"GBP", "languages": "en,fr", },
jm: {name:"Jamaica", "native":"Jamaica", "phone": "1876", "continent": "NA", "capital": "Kingston", "currency":"JMD", "languages": "en", },
jo: {name:"Jordan", "native":"الأردن", "phone": "962", "continent": "AS", "capital": "Amman", "currency":"JOD", "languages": "ar", },
jp: {name:"Japan", "native":"日本", "phone": "81", "continent": "AS", "capital": "Tokyo", "currency":"JPY", "languages": "ja", },
ke: {name:"Kenya", "native":"Kenya", "phone": "254", "continent": "AF", "capital": "Nairobi", "currency":"KES", "languages": "en,sw", },
kg: {name:"Kyrgyzstan", "native":"Кыргызстан", "phone": "996", "continent": "AS", "capital": "Bishkek", "currency":"KGS", "languages": "ky,ru", },
kh: {name:"Cambodia", "native":"Kâmpŭchéa", "phone": "855", "continent": "AS", "capital": "Phnom Penh", "currency":"KHR", "languages": "km", },
ki: {name:"Kiribati", "native":"Kiribati", "phone": "686", "continent": "OC", "capital": "South Tarawa", "currency":"AUD", "languages": "en", },
km: {name:"Comoros", "native":"Komori", "phone": "269", "continent": "AF", "capital": "Moroni", "currency":"KMF", "languages": "ar,fr", },
kn: {name:"Saint Kitts and Nevis", "native":"Saint Kitts and Nevis", "phone": "1869", "continent": "NA", "capital": "Basseterre", "currency":"XCD", "languages": "en", },
kp: {name:"North Korea", "native":"북한", "phone": "850", "continent": "AS", "capital": "Pyongyang", "currency":"KPW", "languages": "ko", },
kr: {name:"South Korea", "native":"대한민국", "phone": "82", "continent": "AS", "capital": "Seoul", "currency":"KRW", "languages": "ko", },
kw: {name:"Kuwait", "native":"الكويت", "phone": "965", "continent": "AS", "capital": "Kuwait City", "currency":"KWD", "languages": "ar", },
ky: {name:"Cayman Islands", "native":"Cayman Islands", "phone": "1345", "continent": "NA", "capital": "George Town", "currency":"KYD", "languages": "en", },
kz: {name:"Kazakhstan", "native":"Қазақстан", "phone": "76,77", "continent": "AS", "capital": "Astana", "currency":"KZT", "languages": "kk,ru", },
la: {name:"Laos", "native":"ສປປລາວ", "phone": "856", "continent": "AS", "capital": "Vientiane", "currency":"LAK", "languages": "lo", },
lb: {name:"Lebanon", "native":"لبنان", "phone": "961", "continent": "AS", "capital": "Beirut", "currency":"LBP", "languages": "ar,fr", },
lc: {name:"Saint Lucia", "native":"Saint Lucia", "phone": "1758", "continent": "NA", "capital": "Castries", "currency":"XCD", "languages": "en", },
li: {name:"Liechtenstein", "native":"Liechtenstein", "phone": "423", "continent": "EU", "capital": "Vaduz", "currency":"CHF", "languages": "de", },
lk: {name:"Sri Lanka", "native":"śrī laṃkāva", "phone": "94", "continent": "AS", "capital": "Colombo", "currency":"LKR", "languages": "si,ta", },
lr: {name:"Liberia", "native":"Liberia", "phone": "231", "continent": "AF", "capital": "Monrovia", "currency":"LRD", "languages": "en", },
ls: {name:"Lesotho", "native":"Lesotho", "phone": "266", "continent": "AF", "capital": "Maseru", "currency":"ZAR", "languages": "en,st", },
lt: {name:"Lithuania", "native":"Lietuva", "phone": "370", "continent": "EU", "capital": "Vilnius", "currency":"LTL", "languages": "lt", },
lu: {name:"Luxembourg", "native":"Luxembourg", "phone": "352", "continent": "EU", "capital": "Luxembourg", "currency":"EUR", "languages": "fr,de,lb", },
lv: {name:"Latvia", "native":"Latvija", "phone": "371", "continent": "EU", "capital": "Riga", "currency":"EUR", "languages": "lv", },
ly: {name:"Libya", "native":"ليبيا", "phone": "218", "continent": "AF", "capital": "Tripoli", "currency":"LYD", "languages": "ar", },
ma: {name:"Morocco", "native":"المغرب", "phone": "212", "continent": "AF", "capital": "Rabat", "currency":"MAD", "languages": "ar", },
mc: {name:"Monaco", "native":"Monaco", "phone": "377", "continent": "EU", "capital": "Monaco", "currency":"EUR", "languages": "fr", },
md: {name:"Moldova", "native":"Moldova", "phone": "373", "continent": "EU", "capital": "Chișinău", "currency":"MDL", "languages": "ro", },
me: {name:"Montenegro", "native":"Црна Гора", "phone": "382", "continent": "EU", "capital": "Podgorica", "currency":"EUR", "languages": "sr,bs,sq,hr", },
mf: {name:"Saint Martin", "native":"Saint-Martin", "phone": "590", "continent": "NA", "capital": "Marigot", "currency":"EUR", "languages": "en,fr,nl", },
mg: {name:"Madagascar", "native":"Madagasikara", "phone": "261", "continent": "AF", "capital": "Antananarivo", "currency":"MGA", "languages": "fr,mg", },
mh: {name:"Marshall Islands", "native":"M̧ajeļ", "phone": "692", "continent": "OC", "capital": "Majuro", "currency":"USD", "languages": "en,mh", },
mk: {name:"Macedonia", "native":"Македонија", "phone": "389", "continent": "EU", "capital": "Skopje", "currency":"MKD", "languages": "mk", },
ml: {name:"Mali", "native":"Mali", "phone": "223", "continent": "AF", "capital": "Bamako", "currency":"XOF", "languages": "fr", },
mm: {name:"Myanmar [Burma]", "native":"Myanma", "phone": "95", "continent": "AS", "capital": "Naypyidaw", "currency":"MMK", "languages": "my", },
mn: {name:"Mongolia", "native":"Монгол улс", "phone": "976", "continent": "AS", "capital": "Ulan Bator", "currency":"MNT", "languages": "mn", },
mo: {name:"Macao", "native":"澳門", "phone": "853", "continent": "AS", "capital": "", "currency":"MOP", "languages": "zh,pt", },
mp: {name:"Northern Mariana Islands", "native":"Northern Mariana Islands", "phone": "1670", "continent": "OC", "capital": "Saipan", "currency":"USD", "languages": "en,ch", },
mq: {name:"Martinique", "native":"Martinique", "phone": "596", "continent": "NA", "capital": "Fort-de-France", "currency":"EUR", "languages": "fr", },
mr: {name:"Mauritania", "native":"موريتانيا", "phone": "222", "continent": "AF", "capital": "Nouakchott", "currency":"MRO", "languages": "ar", },
ms: {name:"Montserrat", "native":"Montserrat", "phone": "1664", "continent": "NA", "capital": "Plymouth", "currency":"XCD", "languages": "en", },
mt: {name:"Malta", "native":"Malta", "phone": "356", "continent": "EU", "capital": "Valletta", "currency":"EUR", "languages": "mt,en", },
mu: {name:"Mauritius", "native":"Maurice", "phone": "230", "continent": "AF", "capital": "Port Louis", "currency":"MUR", "languages": "en", },
mv: {name:"Maldives", "native":"Maldives", "phone": "960", "continent": "AS", "capital": "Malé", "currency":"MVR", "languages": "dv", },
mw: {name:"Malawi", "native":"Malawi", "phone": "265", "continent": "AF", "capital": "Lilongwe", "currency":"MWK", "languages": "en,ny", },
mx: {name:"Mexico", "native":"México", "phone": "52", "continent": "NA", "capital": "Mexico City", "currency":"MXN", "languages": "es", },
my: {name:"Malaysia", "native":"Malaysia", "phone": "60", "continent": "AS", "capital": "Kuala Lumpur", "currency":"MYR", "languages": "en", },
mz: {name:"Mozambique", "native":"Moçambique", "phone": "258", "continent": "AF", "capital": "Maputo", "currency":"MZN", "languages": "pt", },
na: {name:"Namibia", "native":"Namibia", "phone": "264", "continent": "AF", "capital": "Windhoek", "currency":"NAD", "languages": "en,af", },
nc: {name:"New Caledonia", "native":"Nouvelle-Calédonie", "phone": "687", "continent": "OC", "capital": "Nouméa", "currency":"XPF", "languages": "fr", },
ne: {name:"Niger", "native":"Niger", "phone": "227", "continent": "AF", "capital": "Niamey", "currency":"XOF", "languages": "fr", },
nf: {name:"Norfolk Island", "native":"Norfolk Island", "phone": "672", "continent": "OC", "capital": "Kingston", "currency":"AUD", "languages": "en", },
ng: {name:"Nigeria", "native":"Nigeria", "phone": "234", "continent": "AF", "capital": "Abuja", "currency":"NGN", "languages": "en", },
ni: {name:"Nicaragua", "native":"Nicaragua", "phone": "505", "continent": "NA", "capital": "Managua", "currency":"NIO", "languages": "es", },
nl: {name:"Netherlands", "native":"Nederland", "phone": "31", "continent": "EU", "capital": "Amsterdam", "currency":"EUR", "languages": "nl", },
no: {name:"Norway", "native":"Norge", "phone": "47", "continent": "EU", "capital": "Oslo", "currency":"NOK", "languages": "no,nb,nn", },
np: {name:"Nepal", "native":"नपल", "phone": "977", "continent": "AS", "capital": "Kathmandu", "currency":"NPR", "languages": "ne", },
nr: {name:"Nauru", "native":"Nauru", "phone": "674", "continent": "OC", "capital": "Yaren", "currency":"AUD", "languages": "en,na", },
nu: {name:"Niue", "native":"Niuē", "phone": "683", "continent": "OC", "capital": "Alofi", "currency":"NZD", "languages": "en", },
nz: {name:"New Zealand", "native":"New Zealand", "phone": "64", "continent": "OC", "capital": "Wellington", "currency":"NZD", "languages": "en,mi", },
om: {name:"Oman", "native":"عمان", "phone": "968", "continent": "AS", "capital": "Muscat", "currency":"OMR", "languages": "ar", },
pa: {name:"Panama", "native":"Panamá", "phone": "507", "continent": "NA", "capital": "Panama City", "currency":"USD", "languages": "es", },
pe: {name:"Peru", "native":"Perú", "phone": "51", "continent": "SA", "capital": "Lima", "currency":"PEN", "languages": "es", },
pf: {name:"French Polynesia", "native":"Polynésie française", "phone": "689", "continent": "OC", "capital": "Papeetē", "currency":"XPF", "languages": "fr", },
pg: {name:"Papua New Guinea", "native":"Papua Niugini", "phone": "675", "continent": "OC", "capital": "Port Moresby", "currency":"PGK", "languages": "en", },
ph: {name:"Philippines", "native":"Pilipinas", "phone": "63", "continent": "AS", "capital": "Manila", "currency":"PHP", "languages": "en", },
pk: {name:"Pakistan", "native":"Pakistan", "phone": "92", "continent": "AS", "capital": "Islamabad", "currency":"PKR", "languages": "en,ur", },
pl: {name:"Poland", "native":"Polska", "phone": "48", "continent": "EU", "capital": "Warsaw", "currency":"PLN", "languages": "pl", },
pm: {name:"Saint Pierre and Miquelon", "native":"Saint-Pierre-et-Miquelon", "phone": "508", "continent": "NA", "capital": "Saint-Pierre", "currency":"EUR", "languages": "fr", },
pn: {name:"Pitcairn Islands", "native":"Pitcairn Islands", "phone": "64", "continent": "OC", "capital": "Adamstown", "currency":"NZD", "languages": "en", },
pr: {name:"Puerto Rico", "native":"Puerto Rico", "phone": "1787,1939", "continent": "NA", "capital": "San Juan", "currency":"USD", "languages": "es,en", },
ps: {name:"Palestine", "native":"فلسطين", "phone": "970", "continent": "AS", "capital": "Ramallah", "currency":"ILS", "languages": "ar", },
pt: {name:"Portugal", "native":"Portugal", "phone": "351", "continent": "EU", "capital": "Lisbon", "currency":"EUR", "languages": "pt", },
pw: {name:"Palau", "native":"Palau", "phone": "680", "continent": "OC", "capital": "Ngerulmud", "currency":"USD", "languages": "en", },
py: {name:"Paraguay", "native":"Paraguay", "phone": "595", "continent": "SA", "capital": "Asunción", "currency":"PYG", "languages": "es,gn", },
qa: {name:"Qatar", "native":"قطر", "phone": "974", "continent": "AS", "capital": "Doha", "currency":"QAR", "languages": "ar", },
re: {name:"Réunion", "native":"La Réunion", "phone": "262", "continent": "AF", "capital": "Saint-Denis", "currency":"EUR", "languages": "fr", },
ro: {name:"Romania", "native":"România", "phone": "40", "continent": "EU", "capital": "Bucharest", "currency":"RON", "languages": "ro", },
rs: {name:"Serbia", "native":"Србија", "phone": "381", "continent": "EU", "capital": "Belgrade", "currency":"RSD", "languages": "sr", },
ru: {name:"Russia", "native":"Россия", "phone": "7", "continent": "EU", "capital": "Moscow", "currency":"RUB", "languages": "ru", "top": "aaa", },
rw: {name:"Rwanda", "native":"Rwanda", "phone": "250", "continent": "AF", "capital": "Kigali", "currency":"RWF", "languages": "rw,en,fr", },
sa: {name:"Saudi Arabia", "native":"العربية السعودية", "phone": "966", "continent": "AS", "capital": "Riyadh", "currency":"SAR", "languages": "ar", },
sb: {name:"Solomon Islands", "native":"Solomon Islands", "phone": "677", "continent": "OC", "capital": "Honiara", "currency":"SBD", "languages": "en", },
sc: {name:"Seychelles", "native":"Seychelles", "phone": "248", "continent": "AF", "capital": "Victoria", "currency":"SCR", "languages": "fr,en", },
sd: {name:"Sudan", "native":"السودان", "phone": "249", "continent": "AF", "capital": "Khartoum", "currency":"SDG", "languages": "ar,en", },
se: {name:"Sweden", "native":"Sverige", "phone": "46", "continent": "EU", "capital": "Stockholm", "currency":"SEK", "languages": "sv", },
sg: {name:"Singapore", "native":"Singapore", "phone": "65", "continent": "AS", "capital": "Singapore", "currency":"SGD", "languages": "en,ms,ta,zh", },
sh: {name:"Saint Helena", "native":"Saint Helena", "phone": "290", "continent": "AF", "capital": "Jamestown", "currency":"SHP", "languages": "en", },
si: {name:"Slovenia", "native":"Slovenija", "phone": "386", "continent": "EU", "capital": "Ljubljana", "currency":"EUR", "languages": "sl", },
sj: {name:"Svalbard and Jan Mayen", "native":"Svalbard og Jan Mayen", "phone": "4779", "continent": "EU", "capital": "Longyearbyen", "currency":"NOK", "languages": "no", },
sk: {name:"Slovakia", "native":"Slovensko", "phone": "421", "continent": "EU", "capital": "Bratislava", "currency":"EUR", "languages": "sk", },
sl: {name:"Sierra Leone", "native":"Sierra Leone", "phone": "232", "continent": "AF", "capital": "Freetown", "currency":"SLL", "languages": "en", },
sm: {name:"San Marino", "native":"San Marino", "phone": "378", "continent": "EU", "capital": "City of San Marino", "currency":"EUR", "languages": "it", },
sn: {name:"Senegal", "native":"Sénégal", "phone": "221", "continent": "AF", "capital": "Dakar", "currency":"XOF", "languages": "fr", },
so: {name:"Somalia", "native":"Soomaaliya", "phone": "252", "continent": "AF", "capital": "Mogadishu", "currency":"SOS", "languages": "so,ar", },
sr: {name:"Suriname", "native":"Suriname", "phone": "597", "continent": "SA", "capital": "Paramaribo", "currency":"SRD", "languages": "nl", },
ss: {name:"South Sudan", "native":"South Sudan", "phone": "211", "continent": "AF", "capital": "Juba", "currency":"SSP", "languages": "en", },
st: {name:"São Tomé and Príncipe", "native":"São Tomé e Príncipe", "phone": "239", "continent": "AF", "capital": "São Tomé", "currency":"STD", "languages": "pt", },
sv: {name:"El Salvador", "native":"El Salvador", "phone": "503", "continent": "NA", "capital": "San Salvador", "currency":"USD", "languages": "es", },
sx: {name:"Sint Maarten", "native":"Sint Maarten", "phone": "1721", "continent": "NA", "capital": "Philipsburg", "currency":"ANG", "languages": "nl,en", },
sy: {name:"Syria", "native":"سوريا", "phone": "963", "continent": "AS", "capital": "Damascus", "currency":"SYP", "languages": "ar", },
sz: {name:"Swaziland", "native":"Swaziland", "phone": "268", "continent": "AF", "capital": "Lobamba", "currency":"SZL", "languages": "en,ss", },
tc: {name:"Turks and Caicos Islands", "native":"Turks and Caicos Islands", "phone": "1649", "continent": "NA", "capital": "Cockburn Town", "currency":"USD", "languages": "en", },
td: {name:"Chad", "native":"Tchad", "phone": "235", "continent": "AF", "capital": "N'Djamena", "currency":"XAF", "languages": "fr,ar", },
tf: {name:"French Southern Territories", "native":"Territoire des Terres australes et antarctiques fr", "phone": "", "continent": "AN", "capital": "Port-aux-Français", "currency":"EUR", "languages": "fr", },
tg: {name:"Togo", "native":"Togo", "phone": "228", "continent": "AF", "capital": "Lomé", "currency":"XOF", "languages": "fr", },
th: {name:"Thailand", "native":"ประเทศไทย", "phone": "66", "continent": "AS", "capital": "Bangkok", "currency":"THB", "languages": "th", },
tj: {name:"Tajikistan", "native":"Тоҷикистон", "phone": "992", "continent": "AS", "capital": "Dushanbe", "currency":"TJS", "languages": "tg,ru", },
tk: {name:"Tokelau", "native":"Tokelau", "phone": "690", "continent": "OC", "capital": "Fakaofo", "currency":"NZD", "languages": "en", },
tl: {name:"East Timor", "native":"Timor-Leste", "phone": "670", "continent": "OC", "capital": "Dili", "currency":"USD", "languages": "pt", },
tm: {name:"Turkmenistan", "native":"Türkmenistan", "phone": "993", "continent": "AS", "capital": "Ashgabat", "currency":"TMT", "languages": "tk,ru", },
tn: {name:"Tunisia", "native":"تونس", "phone": "216", "continent": "AF", "capital": "Tunis", "currency":"TND", "languages": "ar", },
to: {name:"Tonga", "native":"Tonga", "phone": "676", "continent": "OC", "capital": "Nuku'alofa", "currency":"TOP", "languages": "en,to", },
tr: {name:"Turkey", "native":"Türkiye", "phone": "90", "continent": "AS", "capital": "Ankara", "currency":"TRY", "languages": "tr", },
tt: {name:"Trinidad and Tobago", "native":"Trinidad and Tobago", "phone": "1868", "continent": "NA", "capital": "Port of Spain", "currency":"TTD", "languages": "en", },
tv: {name:"Tuvalu", "native":"Tuvalu", "phone": "688", "continent": "OC", "capital": "Funafuti", "currency":"AUD", "languages": "en", },
tw: {name:"Taiwan", "native":"臺灣", "phone": "886", "continent": "AS", "capital": "Taipei", "currency":"TWD", "languages": "zh", },
tz: {name:"Tanzania", "native":"Tanzania", "phone": "255", "continent": "AF", "capital": "Dodoma", "currency":"TZS", "languages": "sw,en", },
ua: {name:"Ukraine", "native":"Україна", "phone": "380", "continent": "EU", "capital": "Kiev", "currency":"UAH", "languages": "uk", },
ug: {name:"Uganda", "native":"Uganda", "phone": "256", "continent": "AF", "capital": "Kampala", "currency":"UGX", "languages": "en,sw", },
um: {name:"US Minor Outlying Islands", "native":"United States Minor Outlying Islands", "phone": "", "continent": "OC", "capital": "", "currency":"USD", "languages": "en", },
us: {name:"US", "native":"United States", "phone": "1", "continent": "NA", "capital": "Washington D.C.", "currency":"USD", "languages": "en", "top": "aaa", },
uy: {name:"Uruguay", "native":"Uruguay", "phone": "598", "continent": "SA", "capital": "Montevideo", "currency":"UYI", "languages": "es", },
uz: {name:"Uzbekistan", "native":"O‘zbekiston", "phone": "998", "continent": "AS", "capital": "Tashkent", "currency":"UZS", "languages": "uz,ru", },
va: {name:"Vatican", "native":"Vaticano", "phone": "39066,379", "continent": "EU", "capital": "Vatican", "currency":"EUR", "languages": "it,la", },
vc: {name:"Saint Vincent and the Grenadines", "native":"Saint Vincent and the Grenadines", "phone": "1784", "continent": "NA", "capital": "Kingstown", "currency":"XCD", "languages": "en", },
ve: {name:"Venezuela", "native":"Venezuela", "phone": "58", "continent": "SA", "capital": "Caracas", "currency":"VEF", "languages": "es", },
vg: {name:"BVI", "native":"British Virgin Islands", "phone": "1284", "continent": "NA", "capital": "Road Town", "currency":"USD", "languages": "en", },
vi: {name:"U.S. Virgin Islands", "native":"United States Virgin Islands", "phone": "1340", "continent": "NA", "capital": "Charlotte Amalie", "currency":"USD", "languages": "en", },
vn: {name:"Vietnam", "native":"Việt Nam", "phone": "84", "continent": "AS", "capital": "Hanoi", "currency":"VND", "languages": "vi", },
vu: {name:"Vanuatu", "native":"Vanuatu", "phone": "678", "continent": "OC", "capital": "Port Vila", "currency":"VUV", "languages": "bi,en,fr", },
wf: {name:"Wallis and Futuna", "native":"Wallis et Futuna", "phone": "681", "continent": "OC", "capital": "Mata-Utu", "currency":"XPF", "languages": "fr", },
ws: {name:"Samoa", "native":"Samoa", "phone": "685", "continent": "OC", "capital": "Apia", "currency":"WST", "languages": "sm,en", },
xk: {name:"Kosovo", "native":"Republika e Kosovës", "phone": "377,381,386", "continent": "EU", "capital": "Pristina", "currency":"EUR", "languages": "sq,sr", },
ye: {name:"Yemen", "native":"اليَمَن", "phone": "967", "continent": "AS", "capital": "Sana'a", "currency":"YER", "languages": "ar", },
yt: {name:"Mayotte", "native":"Mayotte", "phone": "262", "continent": "AF", "capital": "Mamoudzou", "currency":"EUR", "languages": "fr", },
za: {name:"South Africa", "native":"South Africa", "phone": "27", "continent": "AF", "capital": "Pretoria", "currency":"ZAR", "languages": "af,en,nr,st,ss,tn,ts,ve,xh,zu", },
zm: {name:"Zambia", "native":"Zambia", "phone": "260", "continent": "AF", "capital": "Lusaka", "currency":"ZMK", "languages": "en", },
zw: {name:"Zimbabwe", "native":"Zimbabwe", "phone": "263", "continent": "AF", "capital": "Harare", "currency":"ZWL", "languages": "en,sn,nd", },

}