<template>    
    <div></div>
</template>
<script>
export default {
    mounted() {
        // 
    },
    data: function () {return {
        
    }},        
}
</script>


<style scoped>
.footer{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    padding: 40px 0 0px;   
}

.mobfooter{
    position: fixed;
    /* height: 60px; */
    bottom: 0;
    width: 100vw;
    background: var(--inputBack);
}

.mobicon{
    margin: 0 5vw;
    padding: 10px 0;
}

.text{
    font-size: 12px;
    text-align: left;
    color: var(--g5);
    line-height: 120%;
    letter-spacing: 0.01em;
    margin-bottom: 10px;
}
.text:hover{
    color: var(--g9);    
}
.container{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1200px;
    background: none;

}

.cols{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 50px;
    align-items: flex-start;
    justify-content: flex-start;
}

.logo{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.logo p{
    color:  var(--text);
    text-align: left;
    font-size: 11px;
    line-height: 15px;
    margin-left: 10px;
}


.item{
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.0em;
    color:  var(--g4);
    text-decoration: none;
    margin: 0 0px 6px;
    white-space: nowrap;
}
.item.router-link-exact-active{
    opacity: .3;
}
</style>

