import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
const routes: Array<RouteRecordRaw> = [  
  {path: '/', name: 'home', component: () => { return import('@/page/home.vue')}},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
