export default {
    aa: "🇩🇯",
    af: "🇿🇦",
    am: "🇪🇹",
    ar: "🇦🇪",
    ay: "🇧🇴",
    az: "🇦🇿",
    be: "🇧🇾",
    bg: "🇧🇬",
    bi: "🇻🇺",
    bn: "🇧🇩",
    bs: "🇧🇦",
    ca: "🇪🇸",
    ch: "🇬🇺",
    cs: "🇨🇿",
    cy: "🇬🇧",
    da: "🇩🇰",
    de: "🇩🇪",
    dv: "🇲🇻",
    dz: "🇧🇹",
    el: "🇬🇷",
    en: "🇺🇲",
    es: "🇪🇸",
    et: "🇪🇪",
    fa: "🇮🇷",
    fi: "🇫🇮",
    fo: "🇫🇴",
    fr: "🇫🇷",
    ga: "🇮🇪",
    gd: "🇬🇧",
    gn: "🇧🇴",
    he: "🇮🇱",
    hi: "🇮🇳",
    ho: "🇵🇬",
    hr: "🇭🇷",
    ht: "🇭🇹",
    hu: "🇭🇺",
    hy: "🇦🇲",
    id: "🇮🇩",
    is: "🇮🇸",
    it: "🇮🇹",
    ja: "🇯🇵",
    ka: "🇬🇪",
    kk: "🇰🇿",
    kl: "🇬🇱",
    km: "🇰🇭",
    ko: "🇰🇷",
    ku: "🇮🇶",
    kw: "🇬🇧",
    ky: "🇰🇬",
    lb: "🇱🇺",
    lo: "🇱🇦",
    lt: "🇱🇹",
    lv: "🇱🇻",
    mg: "🇲🇬",
    mh: "🇲🇭",
    mi: "🇳🇿",
    mk: "🇲🇰",
    mn: "🇲🇳",
    ms: "🇲🇾",
    mt: "🇲🇹",
    my: "🇲🇲",
    na: "🇳🇷",
    nb: "🇳🇴",
    nd: "🇿🇼",
    ne: "🇳🇵",
    nl: "🇧🇪",
    nn: "🇳🇴",
    no: "🇳🇴",
    ny: "🇲🇼",
    om: "🇪🇹",
    pl: "🇵🇱",
    ps: "🇦🇫",
    pt: "🇵🇹",
    qu: "🇧🇴",
    rm: "🇨🇭",
    ro: "🇷🇴",
    ru: "🇷🇺",
    rw: "🇷🇼",
    se: "🇳🇴",
    sf: "🇳🇦",
    sg: "🇨🇫",
    si: "🇱🇰",
    sk: "🇸🇰",
    sl: "🇸🇮",
    sm: "🇼🇸",
    sn: "🇿🇼",
    so: "🇸🇴",
    sq: "🇦🇱",
    sr: "🇷🇸",
    ss: "🇸🇿",
    st: "🇱🇸",
    sv: "🇸🇪",
    sw: "🇺🇬",
    ta: "🇱🇰",
    tg: "🇹🇯",
    th: "🇹🇭",
    ti: "🇪🇷",
    tk: "🇹🇲",
    tl: "🇵🇭",
    tn: "🇧🇼",
    tr: "🇹🇷",
    uk: "🇺🇦",
    ur: "🇵🇰",
    uz: "🇺🇿",
    vi: "🇻🇳",
    xh: "🇿🇦",
    zh: "🇨🇳",
    zu: "🇿🇦",
}